<template>
  <transition name="menu">
    <form-sifatsurat :is-edit="false"/>
  </transition>
</template>

<script>
import formSifatsurat from './components/formSifatsurat'

export default {
  name: 'CreateSifatSurat',
  components: { formSifatsurat }
}
</script>

